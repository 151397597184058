// Hugo {{ .TableOfContents }}
$toc-background-color: rgb(245, 244, 235);
$toc-font-size: .8rem;
$toc-link-hover-bg-color: rgb(234, 210, 199);
$title-transition-from-background-color: $toc-link-hover-bg-color;
$title-transition-to-background-color: white; // body bg-color

$box-shadow-color: rgba(149, 157, 165, 0.9);
$dark-box-shadow-color: black;

.toc-wrapper {
  font-size: $toc-font-size;
  position: fixed;
  top: 2rem;
  right: 1rem;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: column;

  border-radius: 5px;
  z-index: 1000;


  label {
    background-color: $toc-background-color;

    display: block;
    cursor: pointer;
    padding-left: 0.5rem;
  }
}

#TableOfContents {
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: -0.3rem;
  padding-left: 0.5rem;
  padding-right: 1rem;
  background: $toc-background-color;
  width: 20em;
  border-radius: 5px;
  overscroll-behavior: contain;

  > ul {
    list-style-type: disc;
    padding-left: 0.8rem;

    > li {
      a {
        border-radius: 4px;
        text-decoration: none;
        transition: all .10s;
        display: block;
        border: none;
        padding-left: 0.2rem;
        padding-right: 0.2rem;

        &:hover {
          background: $toc-link-hover-bg-color;
        }

        // See footer.html
        // based on https://codepen.io/saas/pen/LYENgqq
        &.visible-section {
          font-weight: bolder;
        }
      }

      ul {
        list-style-type: disc;
        padding-left: 1em;
      }
    }
  }
}

input#tocToggle {
  display: none;
  background: $toc-background-color;
}

h2:target, h3:target, h4:target, h5:target {
  animation: highlight 3s ease;
}

@keyframes highlight {
  from {
    background: $title-transition-from-background-color;
  }
  to {
    background: $title-transition-to-background-color;
  }
}


@media (prefers-color-scheme: dark) {
  $dark-toc-background-color: rgb(70, 57, 46);
  $dark-toc-link-hover-bg-color: rgb(32, 28, 27);
  $dark-title-transition-from-background-color: #b6a581;
  $dark-title-transition-to-background-color: rgb(36, 32, 25); // body bg-color

  .toc-wrapper {
    label {
      background-color: $dark-toc-background-color;
    }
  }
  #TableOfContents {
    background: $dark-toc-background-color;

    > ul {
      > li {
        a {
          &:hover {
            background: $dark-toc-link-hover-bg-color;
          }
        }
      }
    }
  }
  input#tocToggle {
    background: $dark-toc-background-color;
  }

  @keyframes highlight {
    from {
      background: $dark-title-transition-from-background-color;
    }
    to {
      background: $dark-title-transition-to-background-color;
    }
  }
}

// make toc toggle-able
@media only screen and (max-width: 1799px) {
  .toc-wrapper {
    box-shadow: $box-shadow-color 0 8px 24px 0px;
  }

  input#tocToggle {
    + label {
      font-weight: bold;
      min-width: 2.6em;
      border-radius: 5px;
    }

    &:not(:checked) {
      + label {
        span#toc-title {
          display: initial;
          margin-left: 1em;
        }
      }

      ~ #TableOfContents {
        height: 100%;
      }
    }

    &:checked {
      + label {
        min-height: 1em;

        span#toc-title {
          display: none;
        }
      }

        ~ #TableOfContents {
        display: none;
      }
    }
    ~ #toc-toggle-cover {
      display: none;
    }
  }
}


@media only screen and (max-width: 1799px) and (prefers-color-scheme: dark) {
  .toc-wrapper {
    box-shadow: $dark-box-shadow-color 0 8px 24px 0px;
  }
}

// always display toc on large screens
@media only screen and (min-width: 1800px) {
  input#tocToggle, input#tocToggle:checked, input#tocToggle:not(:checked) {
    + label {
      border-radius: 5px;
      font-weight: bold;
      min-width: 3em;

      span#toc-title {
        display: inline-block;
        margin-left: 1em;
      }
    }

    // prevent the checkbox to be clickable
    ~ #toc-toggle-cover {
      width: 100%;
      height: 3.5em;
      background: transparent;
      position: absolute;
      z-index: 1100;
      top: -1em;
      left: 0;
      display: block;

    }

    ~ #TableOfContents {
      height: 100%;
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .toc-wrapper {
    top: 0.5rem;
    right: 1.2rem;
  }
}